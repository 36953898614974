<template>
  <el-dialog :title="'数据治理流程测试'" :close-on-click-modal="false" :visible.sync="visible">
    <div>
        <el-form label-width="150px">
            <el-form-item label-width="50px">
              <i class="el-icon-info"> 将生成1000条模拟数据用于数据治理流程测试</i>
            </el-form-item>
            <el-form-item label="模拟数据范围">
                <el-col :span="3">
                  <el-form-item>
                    <el-input v-model="mockDataLowRange" style="width: 100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col class="line" style="margin-left: 1%;width: auto"> - </el-col>
                <el-col :span="3" style="margin-left: 1%">
                  <el-form-item>
                    <el-input v-model="mockDataUpRange" style="width: 100%"></el-input>
                  </el-form-item>
                </el-col>
                
            </el-form-item>
            <el-form-item label-width="50px">
              <p v-for="(result,index) in testResult"
                :key="index">
                {{ result }}
              </p>
              
            </el-form-item>
            
            

            <el-form-item>
                <el-button @click="closeDialog()" size="medium">取消</el-button>
                <el-button @click="submit()" :loading="submitLoading" size="medium">测试</el-button>
            </el-form-item>
            
        </el-form>
    </div>
    
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      mockDataLowRange:0,
      mockDataUpRange:0,
      measurePointId:0,
      submitLoading:false,
      testResult:[],
    };
  },
  mounted(){

  },
  methods: {
    /**
     * 初始化信息
     */
    init(id) {
      this.testResult=[];
      this.mockDataLowRange=0;
      this.mockDataUpRange=0;
      this.visible = true;
      this.measurePointId=id;
    }, 
    submit(){
      this.submitLoading=true;
      let data={
        measurePointId:this.measurePointId,
        lowRange:this.mockDataLowRange,
        upRange:this.mockDataUpRange
      }
      console.log(data)
      this.postRequest("/dataQualityIframe/dataGovernPreTest",data).then(response=> {
        this.testResult=response.data.data;
        this.submitLoading=false;
      }).then(error => {
      
      })
    },
    closeDialog(){
      this.visible=false;
    }
    
  },
};
</script>
<style scoped>

</style>